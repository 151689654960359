.theme-default {
  --main-950: #442404;
  --main-900: #74460F;
  --main-800: #89550A;
  --main-700: #A66E02;
  --main-600: #D19A00;
  --main-500: #FFD200;
  --main-400: #FFE10D;
  --main-300: #FFF141;
  --main-200: #FFFB86;
  --main-100: #FFFFC1;
  --main-50: #FFFFE7;
}
